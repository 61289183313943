window.addEventListener('scroll', function() {
  let windowY = window.scrollY;
  const navbar = document.getElementById('navbar');
  if (windowY > 70) {
    navbar.classList.remove('navbar-transbois-absolute');
    navbar.classList.add('navbar-transbois-fixed');
  } else {
    navbar.classList.remove('navbar-transbois-fixed');
    navbar.classList.add('navbar-transbois-absolute');
  }
});
